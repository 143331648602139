import React from 'react';
import './App.css';
import portrait from './portrait.svg';

function ExternalLink(props) {
    return (
        <a href={ props.to } target='_blank' rel='noopener noreferrer'>
            { props.text }
        </a>
    );
}

function Bio() {
    return (
        <div className='bio'>
            <p>Hi, I am Kubilay —a software engineer living in London. Building digital things is what I do for both fun and a living.</p>
            <p>I code at <ExternalLink to='https://www.techatbloomberg.com/' text='Bloomberg LP' /> (formerly at <ExternalLink to='http://panda.network' text='Panda Network' />) and occasionally enjoy taking <ExternalLink to='https://instagram.com/mkubilayk' text='photos' />. Feel free to send me an <ExternalLink to='mailto:mkubilayk@me.com' text='email' /> or follow me on <ExternalLink to='https://twitter.com/mkubilayk' text='Twitter' />.</p>
        </div>
    )
}

function App() {
    return (
        <main>
            <img className='portrait' src={ portrait } alt='portrait' />
            <Bio />
        </main>
    );
}

export default App;
